/* @flow */
import * as React from 'react';
import { Query, Mutation } from 'react-apollo';
import  CreateGroupAdds  from 'elements/CreateGroupAdds';

import {
  Alert,
  Input,
  Button,
  Badge,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  FormText,
  InputGroupAddon,
  Label,
  InputGroup,
} from 'reactstrap';
import type { AssociationProps, AssociationState } from 'shared/types';
import { client } from 'functions/Connect';
import { toggleAssociationSearch } from 'functions/SectionFunctions';

import ModalConfirm from './ModalConfirm';
// @todo: Refactor and remove in favor of AssociationElement.js
/*
 This is left intact as it appears that the updateTerm mutation only allows providers in the payload, and
 not the specialties.
 */
/**
 * Component to handle attaching a one element to another.
 */
class AssociationForm extends React.Component<AssociationProps, AssociationState> {
  waitInterval: number;
  enterKey: number;
  callbacks: {};
  timer: any;

  constructor(props: AssociationProps) {
    super(props);
    this.state = {
      filter: '',
      badgeFilter: '',
      badgeFilterVisible: false,
      email:'',
      emails:[],
      error:false,
      showConfirmDialog: false,
      sl_element:null
    };

    this.filterCurrentBadges = this.filterCurrentBadges.bind(this);
    this.toggleBadgeFilter = this.toggleBadgeFilter.bind(this);
    this.focusBadgeFilter = this.focusBadgeFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.triggerBadgeFilterChange = this.triggerBadgeFilterChange.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.reFocus = this.reFocus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearFilterSearch = this.clearFilterSearch.bind(this);
    this.handleEmail = this.handleEmail.bind(this);

    this.waitInterval = 500;
    this.enterKey = 13;
    this.callbacks = {};
  }

  reFocus = (target: HTMLInputElement) => {
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
   
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  filterCurrentBadges = (elements: Array<any>): Array<any> => {
    if (this.state.badgeFilter.length > 0) {
      return elements.filter(x =>
        x[this.props.elementField].toLowerCase().includes(this.state.badgeFilter.toLowerCase())
      );
    }
    return elements;
  };

  toggleBadgeFilter = () => {
    // Focus on the field when needed.
    if (!this.state.badgeFilterVisible) {
      setTimeout(() => {
        this.focusBadgeFilter();
      }, 500);
    }

    // Toggle the input field.
    this.state.badgeFilterVisible
      ? this.setState(() => ({
          badgeFilterVisible: false 
        }))
      : this.setState(() => ({
          badgeFilterVisible: true
        }));
  };

  focusBadgeFilter = () => {
    const target = document && document.getElementById(`${this.props.slug}AssociationFilter`);
    if (typeof target !== 'undefined' && target !== null && target instanceof HTMLInputElement) {
      target.focus();
    }
  };

  // @todo: consolidate clearFilterSearch and clearSearch functions.
  clearFilterSearch = (e: Event) => {
    const target = document && document.getElementById(`${this.props.slug}AssociationFilter`);
    if (typeof target !== 'undefined' && target !== null && target instanceof HTMLInputElement) {
      target.value = '';
      this.setState(() => ({
        badgeFilter: ''
      }));

      const wrapper = target.closest('.section-search-form--wrapper');
      if (typeof wrapper !== 'undefined' && wrapper !== null) {
        wrapper.classList.remove('active-search');
      }

      target.focus();
    }
    e.preventDefault();
  };

  clearSearch = (e, filterId) => {
    const target = document && document.getElementById(filterId);
    if (typeof target !== 'undefined' && target !== null && target instanceof HTMLInputElement) {
      target.value = '';
      this.setState(() => ({
        filter: ''
      }));

      const wrapper = target.closest('.section-search-form--wrapper');
      if (typeof wrapper !== 'undefined' && wrapper !== null) {
        wrapper.classList.remove('active-search');
      }

      target.focus();
    }
    if(this.props.label != 'Emails'){
      e.preventDefault();
    }
  };

  handleEmails = async(e) => {
  let email = document.getElementById('practiceEmailAssociationList').value;
  
  let emails = this.props.elements;
  if(emails.includes(email)){
    this.setState({
      error: true,
      email: email
      //saved: true
    });

    return '';
  }
  if(email == ''){
    return '';
  }
  emails.push(email.trim()+'@northwell.edu');
  this.setState(()=>({
    emails:emails ? emails: ''
  }));
  emails = JSON.stringify(emails);
  
  const status: boolean = await client
  .mutate({
  
    mutation: this.props.associateMutation,
    // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: this.props.refetchVars.id,
      //  log: this.getLogValue(),

        update: {
          emails: emails,
        
        }
      }
    }
  })
  .then(response => {
     
    const data: {
      success: boolean
    } = response.data.updateEmail;
    return data !== null && data.success;
  })
  .catch(error => console.log(error));

  if (status) {
    // Update the state value(s) as required.
    this.setState({
      formChanged: false,
      saved: true
    });
    this.clearSearch(e);
    

    // Reset the log field value for the next edit.
    
  }
  return status;
  }
  

  handleEmail=async(target )=> {
  
    let emails = this.props.elements;
    var index = emails.indexOf(target);
  if (index > -1) {
    emails.splice(index, 1);
  }

  
  
  this.setState(()=>({
    emails:emails
    
  }));
  emails = JSON.stringify(emails);
  const status: boolean = await client
  .mutate({
  
    mutation: this.props.associateMutation,
    // Ensure we ALWAYS reach out immediately and don't rely on cache for this query.
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: this.props.refetchVars.id,
      //  log: this.getLogValue(),

        update: {
          emails: emails,
        
        }
      }
    }
  })
  .then(response => {
     
    const data: {
      success: boolean
    } = response.data.updateEmail;
    return data !== null && data.success;
  })
  .catch(error => console.log(error));

  if (status) {
    // Update the state value(s) as required.
    this.setState({
      formChanged: false,
      saved: true
    });
    // Reset the log field value for the next edit.
    
  }
  return status;

      
  }
 
  handleChange = ({ target }: { target: HTMLInputElement }) => {

    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  triggerBadgeFilterChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState(() => ({
        badgeFilter: target.value ? target.value : ''
      }));
      if (target.value) {
        this.reFocus(target);
      }
    }, this.waitInterval);
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : ''
    }));
    if (target.value) {
      this.reFocus(target);
    }
  };

  render() {
   
    const visibilityClass = this.state.filter && this.state.filter.length >= 1 ? 'd-block active-search' : 'd-block';
    const visibilityClass2 = this.state.filter && this.state.filter.length >= 1 ? 'active-search' : '';
    const badgeVisibilityClass =
      this.state.badgeFilter && this.state.badgeFilter.length >= 1 ? 'd-block active-search' : 'd-block';
    const CurrentBadges = (data: { elements: Array<any> }) => {
     var elements = data.elements;
    
     if(elements.length > 1 && elements[0].name){
      
       elements.sort((a, b) => a.name.localeCompare(b.name));
     }
      const BadgeList = elements.map((element: any, id) => {
        const elementVariables = {};
        elementVariables[`${this.props.gqlFilterVar}Id`] = element.id;
        const vars = Object.assign({}, this.props.mutationVariables, elementVariables);
       
        return (
          
          <React.Fragment key={id}>
          
            {this.props.label == 'Emails' ? 
              
                <Badge color="tertiary" className="linked-reference-item">

                  {element[this.props.elementField]?element[this.props.elementField]:element}
                  <Button
                    color="danger"
                    className="btn btn-sm specialty-remove-button"
                    onClick={() => {
                      this.handleEmail(element[this.props.elementField]?element[this.props.elementField]:element);
                      this.props.callbacks.handleFormChange();
                     // if(this.state.emails.length > 0){
                       //removeAssociation(element[this.props.elementField]?element[this.props.elementField]:element);
                     // }
                    }}
                  > 
                    <i className="fas fa-times" />
                  </Button>
                </Badge>
            :
            <Mutation
              mutation={this.props.deleteMutation}
              variables={{
                input: vars
              }}
              refetchQueries={[
                {
                  // Clear the cache for the parent query.
                  query: this.props.refetch,
                  variables: this.props.refetchVars,
                  fetchPolicy: 'network-only',  
                }
              ]}
            >
              {(removeAssociation, { loading, error }) => {
                if (loading) {
                  return null;
                }
                if (error) {
                  return (
                    <Badge color="danger" className="error-reference-item">
                      ERROR
                    </Badge>
                  );
                }

                const confirmAction = () => {
                  removeAssociation();
                  this.props.callbacks.handleFormChange(this.state.sl_element, 'delete');
                  this.setState({ showConfirmDialog: false }, () => {
                  });
                }
              
                const toggleServiceLineConfirm = () => {
                  this.setState({showConfirmDialog:false});
                }
              
                const handleServiceLineDelete = async () => {
                  this.setState({ showConfirmDialog: true });
                }
               
                return (
               
                  this.props.label === 'Clinical Focus Terms' || this.props.label === 'CFTs'  ? 
                  
                  <a href={"/cft/edit/"+element.slug}><Badge color="tertiary" className="linked-reference-item">
                   
                    {element[this.props.elementField]?element[this.props.elementField]:element}
                    <Button
                      color="danger"
                      className="btn btn-sm specialty-remove-button"
                      onClick={() => {
                        this.props.callbacks.handleFormChange();
                        removeAssociation();
                      }}
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </Badge></a>
                  
                   :
                  <Badge color="tertiary" className="linked-reference-item">

                  {element[this.props.elementField]?element[this.props.elementField]:element}
                 
                  {this.state.showConfirmDialog && (
                    <ModalConfirm isOpen={true} confirmAction={confirmAction} onClose = {toggleServiceLineConfirm}  />
                  )}
                  <Button
                    color="danger"
                    className="btn btn-sm specialty-remove-button"
                    onClick={async () => {
                      const specialtyFormPropsList = ['variants','inherited_terms_default','specialty_terms','terms'];
                      if(this.props.name === 'service_lines'){
                        this.setState({sl_element:element});
                        await handleServiceLineDelete();
                      }else if(specialtyFormPropsList.includes(this.props.name)){
                        this.props.callbacks.handleFormChange(element, 'delete',this.props.variantSlug);
                        removeAssociation();
                      }else{
                        removeAssociation();
                      }
                      
                    }}
                  >
                    <i className="fas fa-times" />
                  </Button>
                </Badge>

                );  
              }}
            </Mutation>
           }
          </React.Fragment>
          
        );
      });
      return BadgeList;
    };

    const currentAssociations = this.props.elements && this.props.elements.length > 0 ? this.props.elements.length : 0;
    const skip_id = this.props.skip_id || '';
    const filteredAssociations =
      this.props.elements && this.state.badgeFilter.length > 0
        ? this.filterCurrentBadges(this.props.elements).length
        : 0;
     const filterId = (typeof this.props.id !== "undefined" )? this.props.id : `${this.props.slug}AssociationList`;
    return (
      <React.Fragment>
        <Alert color="danger" className={`mb-3 ${this.state.error ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <span>
                    The Email Address {this.state.email} Already Exists.
                  </span>
                </div>
              </Alert>
        <Label for={`relation-list--${this.props.slug}`} className={`d-flex justify-content-between`}>
          <div className={`${filteredAssociations > 0 && this.state.badgeFilterVisible ? 'filtered-list' : ''}`}>
            <span className="d-none d-lg-inline">{ ['variants','inherited_terms_default','specialty_terms'].includes(this.props.name)?'':'Associated ' }</span>
            {this.props.label}
            <span className={`${filteredAssociations > 0 && this.state.badgeFilterVisible ? '' : 'd-none'}`}>
              <Badge className={`label-badge`} color="warning">
                {filteredAssociations}
              </Badge>
              <span className={`label-badge-text`}>of</span>
            </span>

            <Badge className="label-badge label-badge--total" color="primary">
              {currentAssociations}
            </Badge>
            {/* <h3>Test</h3> */}
            <span className={`${filteredAssociations > 0 && this.state.badgeFilterVisible ? '' : 'd-none'}`}>
              <span className={`label-badge-text`}>visible</span>
            </span>
          </div>
          <div>
            <Button
              title={`Filter current associations. Useful for long lists.`}
              className={`association-filter--button ${currentAssociations < 10 ? 'd-none' : ''}${
                this.state.badgeFilterVisible ? 'active' : ''
              }`}
              onClick={(e: Event) => {
                e.preventDefault();
                this.toggleBadgeFilter();
              }}
            >
              <i className="association-filter--icon fas fa-filter" />
            </Button>
          </div>
        </Label>

        <div id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}-filter-field`} className={`${badgeVisibilityClass} section-search-form--wrapper`}>
          <Input
            id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}AssociationFilter`}
            defaultValue={this.state.filter}
            className={`search section-search section-search--field mb-2 ${
              currentAssociations > 10 && this.state.badgeFilterVisible === true ? '' : 'd-none'
            }`}
            autoComplete="off"
            type="text"
            placeholder={`Filter existing ${this.props.label}...`}
            onChange={this.triggerBadgeFilterChange}
          />
          <button
            className="btn close-icon"
            onClick={(e: Event) => {
              this.clearFilterSearch(e);
            }}
          >
            <i className="fas fa-times" />
          </button>
        </div>

        <InputGroup className="relation-list--group">
          <div
            id={`relation-list--${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}`}
            className="relation-list editable-content form-control"
            contentEditable={false}
          >
            <CurrentBadges elements={this.filterCurrentBadges(this.props.elements)} />
          </div>
          <InputGroupAddon addonType="append">
            <Button
              id={`toggle${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug  }Association`}
              color="primary"
              className="association-button association-closed"
              title={`Associated ${this.props.label}`}
              onClick={() => {
                toggleAssociationSearch({
                  div: `${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug }Association`,
                  button: `toggle${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug }Association`,
                  input: `${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug }List`,
                });
              }}
              // disabled={(!this.props.visibility && this.props.name == 'variants')?true:false}
            >
              <i className="fas fa-plus" />
              <i className="fas fa-times" />
            </Button>
            
          </InputGroupAddon>
        </InputGroup>
        {this.props.name === 'variants' ?
        (  <FormText color="muted">
        <i className="fal fa-info-circle" /> To associate <strong>Terms</strong> to {this.props.variantName} Variant Inherited Term List, use the
        the <strong>+</strong> icon to the right.
      </FormText>)
        :(
          <FormText color="muted">
          <i className="fal fa-info-circle" /> To associate new <strong>{this.props.label}</strong> to this group, use
          the <strong>+</strong> icon to the right.
        </FormText>
        
        )
        }
        <div
          id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}Association`}
          className={`association-form d-none association-form--${this.props.slug} mt-4`}
        >
         
          {this.props.label == 'Emails' ? 
          <Card inverse color="primary">
            <CardBody>
              <CardTitle className="">Associated {this.props.label}</CardTitle>
              <div id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}-search-field`}  style={{display:'inline-block'}} className={`${visibilityClass2} section-search-form--wrapper`}>
                <Input
                  id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}AssociationList`}
                 
                  defaultValue={this.state.filter}
                  className="search section-search section-search--field"
                  autoComplete="off"
                  type="text"
                  placeholder={`Email`}
                />
              </div><p style={{display:'inline-block'}}>@northwell.edu</p>
            </CardBody>
            <CardFooter>
            <Button
                id="saveUserButton"
                type="button"
                size="md"
                color="secondary"
                title="Save"
                onClick = {(e: Event) => {this.handleEmails(e)}}
                // disabled={!this.state.submitAvailable}
            >
               <i className="fal fa-save" />
               <span>Save Email</span>
            </Button>
         
            </CardFooter>
            </Card> :
          <Card inverse color="primary">
            <CardBody>
              <CardTitle className="">Associated {this.props.label}</CardTitle>
              <div id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}-search-field`} className={`${visibilityClass} section-search-form--wrapper`}>
                <Input
                  id={filterId}
                  defaultValue={this.state.filter}
                  className="search section-search section-search--field"
                  autoComplete="off"
                  type="text"
                  placeholder={`Search ${this.props.label}...`}
                  onChange={this.handleChange}
                />
                <button className="btn search-icon">
                  <i className="fal fa-search" />
                </button>
                <button
                  
                  className="btn close-icon"
                  onClick={(e: Event) => {
                    this.clearSearch(e,filterId);
                  }}
                >
                  {/* <i className="fas fa-times" /> */}
                  <div className="delete-icon"></div>
                </button>
              </div>
            </CardBody>
         
            {this.props.associateMutation.definitions[0].name.value === 'createGroupProviderAssociation' && (
            <CreateGroupAdds group_id={this.props.mutationVariables}  refetch={this.props.refetch} mutation={this.props.associateMutation} setcreatedTerm={''} />
               
  
            )} 
            <CardFooter
              className={`bg-light ${this.state.filter && this.state.filter.length >= 1 ? 'd-block' : 'd-none'}`}
            >
              
              <div id={`${typeof this.props.id !== "undefined" ? this.props.id : this.props.slug}Results`}>
               
                <Query
                  query={this.props.searchQuery}
                  variables={{
                    skip_id:skip_id,
                    filter: this.state.filter,
                    first: this.props.gqlFirstVar
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ variables, loading, data, error }) => {
                    if (loading) return null;
                    if (error) return `Error!: ${error}`;

                    const reducedElements = [];
                    
                    if(data[this.props.slug]){
                      data[this.props.slug].edges.map((element, id) => {
                        if (
                          typeof this.props.elements !== 'undefined' &&
                          !this.props.elements.find(x => x.id === element.id)
                        ) {
                          reducedElements.push(element.node);
                        }
                        return null;
                      });
                    }
                   
                  
                    if (reducedElements.length === 0) {
                      return (
                        <Alert color="warning" className="mb-0">
                          <div className="alert--with-icon">
                            <i className="alert-icon fal fa-info-circle" />
                            <p className="mb-0">
                              No <em>{this.props.label}</em> results found for search term of{' '}
                              <strong>{this.state.filter}</strong>
                              ...
                            </p>
                          </div>
                        </Alert>
                      );
                    }

                    if (reducedElements.length > 0 && this.state.filter.length >= 1) {
                      return reducedElements.map((element, id) => {
                        const elementVariables = {};
                        elementVariables[`${this.props.gqlFilterVar}Id`] = element.id;
                        const vars = Object.assign({}, this.props.mutationVariables, elementVariables);
                        return (
                          <React.Fragment key={element.id}>
                            <Mutation
                              mutation={this.props.associateMutation}
                              variables={{
                                input: vars
                              }}
                              refetchQueries={[
                                {
                                  // Clear the cache for the parent query.
                                  query: this.props.refetch,
                                  variables: this.props.refetchVars
                                }
                              ]}
                            >
                              {(addAssociation, { loading, error }) => {
                                if (loading) {
                                  return null;
                                }
                                if (error) {
                                  return (
                                    <Badge color="danger" className="error-reference-item">
                                      ERROR
                                    </Badge>
                                  );
                                }
                              
                                return (
                                  
                                  <Badge key={element.id} color="tertiary" className="linked-reference-item">
                                    {element[this.props.elementField]}
                                    <Button
                                      color="success"
                                      className={`btn btn-sm ${this.props.slug}-relate-button`}
                                      onClick={() => {
                                        if(this.props.name === "variants"){
                                          this.props.callbacks.handleFormChange(element, "add", this.props.variantSlug);
                                        }else if(this.props.name === "inherited_terms_default" || this.props.name == "service_lines" || this.props.name == "specialty_terms" || this.props.name == "terms"){
                                          this.props.callbacks.handleFormChange(element, "add");
                                        }else{
                                          this.props.callbacks.handleFormChange();
                                        }
                                        
                                        addAssociation();
                                      }}
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                  </Badge>
                                );
                              }}
                            </Mutation>
                            
                          </React.Fragment>
                        );
                      });
                    }

                    return null;
                  }}
                </Query>
              </div>
            </CardFooter>
          </Card>
         
                }
        </div>
      </React.Fragment>
    );
  }
}

export default AssociationForm;
